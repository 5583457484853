import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage: React.FC = () => (
  <>
    <SEO title="Building digital products" />
    <Layout>
      <div className="flex items-center w-full">
        <div className="p-6 w-full">
          <h2 className="font-serif text-4xl">
            I help companies build digital products.
          </h2>
          <div className="mt-6 sm:w-2/6">
            <p>Hey, I’m Oleg, software engineer based in London 🇬🇧</p>
            <p className="mt-3">
              In the last 15 years, I’ve worked on more than 100 projects - from
              helping companies to come up with a product idea to working on IoT
              startups.{" "}
            </p>
            <p className="mt-3">
              <Link
                to="/contact/"
                title="Contact Oleg Gera"
                className="font-bold"
              >
                Get in touch
              </Link>{" "}
              if you need help with your projects. I also{" "}
              <Link to="/blog/" title="Oleg Gera blog" className="font-bold">
                blog
              </Link>
              , and build my{" "}
              <Link
                to="/projects/"
                title="Personal projects"
                className="font-bold"
              >
                projects
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </Layout>
  </>
);

export default IndexPage;
